import React from "react"
import styled from "@emotion/styled"
import Text from './text';

const ExternalLink = styled("a")({
    color: "inherit",
    textDecoration: "none",
})

export const ExternalLinkText = ({ href, children }) => (
    <Text tag="a" href={href} weight="semibold" style={{ color: "#487796", textDecoration: "none" }}>
        {children}
    </Text >
)

export default ExternalLink
