import rem from "../utils/rem"

export const pageWidth = rem(1124)

export const sizes = {
  xsm: {
    value: rem(14),
    letterSpacing: "-0.18px",
    responsive: {
      value: rem(10),
      letterSpacing: 0,
    },
  },
  sm: {
    value: rem(16),
    letterSpacing: 0,
    responsive: {
      value: rem(14),
      letterSpacing: "-0.18px",
    },
  },
  md: {
    value: rem(18),
    letterSpacing: "0.3px",
    responsive: {
      value: rem(16),
      letterSpacing: "0.27px",
    },
  },
  lg: {
    value: rem(24),
    letterSpacing: "0.4px",
    responsive: {
      value: rem(20),
      letterSpacing: "0.33px",
    },
  },
  xlg: {
    value: rem(42),
    letterSpacing: "0.7px",
    responsive: {
      value: rem(26),
      letterSpacing: "0.43px",
    },
  },
  xxlg: {
    value: rem(62),
    letterSpacing: "1.03px",
    responsive: {
      value: rem(32),
      letterSpacing: "0.53px",
    },
  },
}

export const weights = {
  extralight: {
    value: 200,
  },
  light: {
    value: 300,
  },
  normal: {
    value: 400,
  },
  semibold: {
    value: 500,
  },
  bold: {
    value: 700,
  },
  black: {
    balue: 900,
  },
}
