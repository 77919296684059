import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import rem from "../utils/rem"
import useWindowScrollPosition from "../utils/useWindowScrollPosition"
import { sizes, weights } from "../styles/tokens"
import Nav from "./nav"
import List from "./list"
import ListItem from "./listItem"
import menuItems from "./mainMenu.json"
import flex from "../styles/flex"
import { breakpoints, smaller } from "../styles/responsive"

function isElementInViewPort(id) {
  if (!id || typeof window === "undefined") {
    return false
  }
  const element = document.getElementById(id)
  if (!element) {
    return false
  }
  const rect = element.getBoundingClientRect()
  return Boolean(
    Math.abs(rect.top) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom >= 0
  )
}

const MenuLink = styled(Link)(({ color = "#fff", active = false }) => ({
  fontSize: sizes.xsm.value,
  fontWeight: weights.bold.value,
  letterSpacing: sizes.xsm.letterSpacing,
  width: rem(130),
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  opacity: active ? 1 : 0.6,
  color: color,
  transition: "all 0.5s ease-in-out",

  ":hover": {
    textDecoration: "underline",
  },

  [smaller(breakpoints.sm)]: {
    width: "auto",
    padding: `0 ${rem(5)}`,
    fontSize: sizes.xsm.responsive.value,
    letterSpacing: sizes.xsm.responsive.letterSpacing,
  },
}))

const MenuEntry = ({ id, ...props }) => {
  const [active, setActive] = useState(false)
  const position = useWindowScrollPosition()
  useEffect(() => {
    setActive(isElementInViewPort(id))
  }, [position])

  return <MenuLink {...props} active={active ? 1 : 0} />
}

const MenuItem = ({ title, anchor, color = "#fff" }) => (
  <ListItem>
    <MenuEntry
      to={anchor}
      activeStyle={{ opacity: 1 }}
      color={color}
      id={anchor.slice(2)}
    >
      {title}
    </MenuEntry>
  </ListItem>
)
const MenuList = props => (
  <List
    className="nav navbar-nav"
    role="menubar"
    aria-hidden="false"
    css={{
      ...flex.horizontal,
      ...flex.justifyEnd,
      [smaller(breakpoints.md)]: {
        padding: 0,
      },
    }}
    {...props}
  />
)
const Menu = props => (
  <Nav
    title="Main Menu"
    style={{ ...flex.horizontal, ...flex.centerHorizontal }}
  >
    <MenuList component={MenuItem} items={menuItems} {...props} />
  </Nav>
)

export default React.memo(Menu)
