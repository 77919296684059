import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Ul = styled("ul")({
  listStyle: "none",
  margin: 0,
  width: "100%",
  padding: "0 1em",
})

function List({ items, component, ...rest }) {
  const ComponentToRender = component
  let content = <div />

  // If we have items, render them
  if (items) {
    content = items.map(item => (
      <ComponentToRender key={`item-${item.id}`} {...item} {...rest} />
    ))
  } else {
    // Otherwise render a single component
    content = <ComponentToRender />
  }

  return <Ul {...rest}>{content}</Ul>
}

List.propTypes = {
  component: PropTypes.func.isRequired,
  items: PropTypes.array,
}

export default List
