import styled from "@emotion/styled"

import { pageWidth } from "../styles/tokens"
import rem from "../utils/rem"
import { breakpoints, smaller } from "../styles/responsive"

export default styled("div")({
  maxWidth: pageWidth,
  margin: "0 auto",
  width: "100%",
  padding: `0 ${rem(50)}`,
  [smaller(breakpoints.sm)]: {
    padding: `0 ${rem(15)}`,
  },
})
