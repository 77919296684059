import React from "react"

export default function useScrollToSectionOnLoad() {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash) {
        window.scrollTo(
          0,
          document.getElementById(window.location.hash.slice(1)).offsetTop
        )
      }
    }
  }, [])
}
