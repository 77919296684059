import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

function ListItem({ children, ...rest }) {
  return <Item {...rest}>{children}</Item>
}

ListItem.propTypes = {
  children: PropTypes.node,
}

export default ListItem
