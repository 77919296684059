import PropTypes from "prop-types"
import React from "react"

import VisuallyHidden from "./visuallyHidden"

const Nav = ({ children, title, ...props }) => (
  <nav className="navbar" aria-label={title} {...props}>
    <VisuallyHidden as="h2">{title}</VisuallyHidden>
    {children}
  </nav>
)

Nav.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Nav
