import { useState, useEffect } from "react"
import throttle from "lodash.throttle"

let getPosition = () => ({
  x: typeof window !== "undefined" ? window.pageXOffset : 0,
  y: typeof window !== "undefined" ? window.pageYOffset : 0,
})

let defaultOptions = {
  throttle: 100,
}

function useWindowScrollPosition(options) {
  let opts = Object.assign({}, defaultOptions, options)

  let [position, setPosition] = useState(getPosition())

  useEffect(() => {
    let handleScroll = throttle(() => {
      setPosition(getPosition())
    }, opts.throttle)

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, true)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return position
}

export default useWindowScrollPosition
