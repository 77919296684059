import React from "react"
import { Link } from "gatsby"
import Text from "./text"
import flex from "../styles/flex"
import { pageWidth, sizes, weights } from "../styles/tokens"
import InstagramSvg from "../assets/instagram.svg"
import EmailSvg from "../assets/mail.svg"
import PhoneSvg from "../assets/phone.svg"
import AddressSvg from "../assets/locate.svg"
import TermsAndConditionsSvg from "../assets/terms-and-conditions.svg"
import rem from "../utils/rem"
import { breakpoints, smaller } from "../styles/responsive"
import ExternalLink from "./externalLink.js";


const itemStyle = {
  ...flex.vertical,
  ...flex.centerHorizontalV,
  textAlign: "center",
  flexBasis: 0,
  flexGrow: 1,
  [smaller(breakpoints.sm)]: {
    flexBasis: "auto",
  },
}

const IconLabel = ({ icon, label }) => (
  <div style={{ ...flex.vertical, ...flex.centerHorizontalV }}>
    <span
      style={{
        ...flex.horizontal,
        ...flex.centerHorizontal,
        width: rem(25),
        height: rem(25),
      }}
    >
      {icon}
    </span>
    <span
      style={{
        color: "#C3C4C3",
        fontSize: sizes.xsm.value,
        fontWeight: weights.normal,
        padding: rem(10),
      }}
    >
      {label}
    </span>
  </div>
)

const InstagramItem = ({ type }) => (
  <div css={itemStyle}>
    <IconLabel icon={<InstagramSvg />} label="Instagram" />
    <Text size="sm" weight="normal">
      <ExternalLink href="https://www.instagram.com/velectronic.bcn/">
        Velectronic.bcn
      </ExternalLink>
    </Text>
  </div>
)

const EmailItem = ({ type }) => (
  <div css={itemStyle}>
    <IconLabel icon={<EmailSvg />} label="Email" />
    <Text size="sm" weight="normal">
      <ExternalLink href="mailto:info@velectronic.net">
        info@velectronic.net
      </ExternalLink>
    </Text>
  </div>
)

const PhoneItem = ({ type }) => (
  <div css={itemStyle}>
    <IconLabel icon={<PhoneSvg />} label="Phone" />
    <Text size="sm" weight="normal">
      <ExternalLink href="tel:+34937142637">tel. 937142637</ExternalLink>
      <br />
      <ExternalLink href="tel:+34937142637">fax. 937142697</ExternalLink>
    </Text>
  </div>
)

const AddressItem = ({ type }) => (
  <div css={itemStyle}>
    <IconLabel icon={<AddressSvg />} label="Address" />
    <ExternalLink href="https://g.page/velectronic?share">
      <Text size="sm" weight="normal">
        C/Montsià, 48
        <br />
        CP 08211, Castellar del Vallès (Barcelona Spain)
        <br />
        Polígon industrial Can Carner.
        <br />
      </Text>
    </ExternalLink>
  </div>
)

const TermsAndConditionsItem = ({ type }) => (
  <div css={itemStyle}>
    <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
      <IconLabel icon={<TermsAndConditionsSvg />} label="Terms &amp; Conditions" />
    </Link>
  </div>
)

const Footer = () => (
  <footer
    style={{
      background: `#fff`,
      margin: `${rem(50)} 0`,
    }}
    id="contact"

  >
    <div
      css={{
        margin: `0 auto`,
        maxWidth: pageWidth,
        padding: `${rem(25)} 0`,
        ...flex.horizontal,
        ...flex.spaceBetween,
        [smaller(breakpoints.sm)]: {
          ...flex.vertical,
        },
      }}
    >
      <InstagramItem />
      <EmailItem />
      <PhoneItem />
      <AddressItem />
      <TermsAndConditionsItem />
    </div>

  </footer>
)

export default Footer
