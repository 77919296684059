import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import useWindowScrollPosition from "../utils/useWindowScrollPosition"

import { breakpoints, smaller } from "../styles/responsive"
import flex from "../styles/flex"
import Menu from "./menu"
import LogoSvg from "../assets/logo-black.svg"
import VisuallyHidden from "./visuallyHidden"
import rem from "../utils/rem"

const Header = ({ siteTitle, children }) => {
  const options = {
    throttle: 100,
  }
  const position = useWindowScrollPosition(options)
  let color = "#fff"
  if (typeof document !== `undefined`) {
    const el = document.getElementsByTagName("header")[0]
    if (el) {
      color = position.y > el.getBoundingClientRect().height ? "#000" : "#fff"
    }
  }

  return (
    <header>
      <div
        css={{
          padding: `${rem(35)} ${rem(25)} 0 ${rem(25)}`,
          ...flex.horizontal,
          ...flex.spaceBetween,
          position: "fixed",
          zIndex: 10,
          width: "100%",
          [smaller(breakpoints.sm)]: {
            padding: `${rem(16)} ${rem(25)} 0 ${rem(16)}`,
          },
        }}
      >
        <h1 style={{ marginTop: rem(5), marginBottom: 0 }}>
          <Link to="/">
            <VisuallyHidden>{siteTitle}</VisuallyHidden>
            <LogoSvg
              aria-label={siteTitle}
              css={{
                g: {
                  fill: color,
                  transition: "fill 0.5s ease-in-out",
                },
                [smaller(breakpoints.sm)]: {
                  width: rem(75),
                },
              }}
            />
          </Link>
        </h1>

        <Menu color={color} />
      </div>
      {children}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default React.memo(Header)
