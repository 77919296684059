import PropTypes from "prop-types"
import React from "react"

import { breakpoints, smaller } from "../styles/responsive"
import { sizes, weights } from "../styles/tokens"
import rem from "../utils/rem"

const Child = ({ Tag, children, ...rest }) => <Tag {...rest}>{children}</Tag>

const Text = ({ children, size, weight, tag, style, href }) => (
  <Child
    Tag={tag}
    css={{
      color: "#4B5E6C",
      fontSize: sizes[size].value,
      fontWeight: weights[weight].value,
      letterSpacing: sizes[size].letterSpacing,
      ...style,
      [smaller(breakpoints.sm)]: {
        fontSize: sizes[size].responsive.value,
        letterSpacing: sizes[size].responsive.letterSpacing,
        ...(style ? { ...style[smaller(breakpoints.sm)] } : {}),
      },
    }}
    href={href}
  >
    {children}
  </Child>
)

Text.defaultProps = {
  size: "md",
  weight: "normal",
  tag: "p",
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  weight: PropTypes.string,
  tag: PropTypes.string,
  style: PropTypes.object,
}

export const HeaderText2 = ({ children }) => (
  <Text tag="h2" size="xlg" weight="semibold" style={{
    color: "#1C1B1F", margin: `${rem(50)} 0 ${rem(50)}`,
    [smaller(breakpoints.sm)]: {
      margin: `${rem(75)} 0 ${rem(25)}`,
    },
  }}>
    {children}
  </Text>
)

export const Label = ({ children }) => (
  <Text weight="semibold" style={{ color: "#1C1B1F" }}>
    {children}
  </Text>
)

export const SubLabel = ({ children }) => (
  <Text weight="semibold" style={{ color: "#707070" }}>
    {children}
  </Text>
)

export default Text
